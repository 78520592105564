import React, { ReactNode, useMemo, useReducer } from 'react';
import { useEnrollmentLearningObject } from '@brainstud/academy-api/Hooks/useEnrollmentLearningObjects';
import { useExpositionLearningObject } from '@brainstud/academy-api/Hooks/useExpositionLearningObjects';
import { useLearningObject } from '@brainstud/academy-api/Hooks/useLearningObjects';
import { useQuiz } from '@brainstud/academy-api/Hooks/useQuizzes';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { useRouter } from 'next/router';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { Wrap } from '../../Components/Wrap';
import { useLearningRouteProvider } from '../LearningRouteProvider/useLearningRouteProvider';
import { LearningSubjectProvider } from '../LearningSubjectProvider';
import {
  LearningObjectContext,
  TLearningObjectIndeterminate,
} from './LearningObjectContext';
import {
  InitialLearningObjectState,
  LearningObjectReducer,
} from './LearningObjectReducer';

type Props = {
  /** Provide an enrollment ID to fetch progress of the learning object */
  enrollmentId?: string;
  children: ReactNode;
  /** Whether the learning object is accessed via a share */
  shared?: boolean;
  /** Add an 'override for the current learning object. E.g. in the ExternalRatingView' */
  learningObject?: LearningObject;
};

/**
 * LearningObjectProvider.
 *
 * The LearningObjectProvider will serve a learning object based on where it's used inside the application. It will
 * also provide varieties or current variety of the learningObject with a dispatch the variety can be changed.
 */
export const LearningObjectProvider = ({
  children,
  enrollmentId: givenEnrollmentId,
  shared,
  learningObject: providedLearningObject,
}: Props) => {
  const [{ varietyId }, dispatch] = useReducer(
    LearningObjectReducer,
    InitialLearningObjectState
  );
  const { query } = useRouter();
  const { learningObjectId, expositionId } = query as {
    learningObjectId: string;
    expositionId?: string;
  };

  const { parameters } = useApi();

  const enrollmentId = (givenEnrollmentId ||
    query.enrollmentId ||
    parameters.enrollment) as string;

  const [{ data: enrolledLearningObject }, { isLoading: isEnrolledLoading }] =
    useEnrollmentLearningObject(
      {
        learningObject: learningObjectId,
        enrollment: enrollmentId,
        shared: !!shared,
        include: ['answers', 'quiz.answer_groups'],
        filter: {
          'quiz.answer_groups.latest': true,
        },
      },
      {
        enabled: !!enrollmentId && !expositionId && !providedLearningObject,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        staleTime: Infinity,
      }
    );

  const hasEnrollment =
    !!enrollmentId || isEnrolledLoading || !!enrolledLearningObject;

  const [
    { data: unenrolledLearningObject },
    { isLoading: isUnenrolledLoading },
  ] = useLearningObject(
    {
      learningObject: learningObjectId,
      include: [
        'varieties',
        'varieties.dossier_workprocesses',
        'varieties.dossier_workprocess_behaviours',
        'varieties.dossier_knowledge_skills',
        'nfc_tags',
      ],
    },
    {
      enabled: !hasEnrollment && !expositionId && !providedLearningObject,
      staleTime: Infinity,
    }
  );

  const [
    { data: expositionLearningObject },
    { isLoading: isExpositionLoading },
  ] = useExpositionLearningObject(
    {
      exposition: expositionId,
      learningObject: learningObjectId,
      include: ['answers'],
    },
    { enabled: !!expositionId && !providedLearningObject }
  );

  const learningObject: TLearningObjectIndeterminate | undefined =
    providedLearningObject ||
    expositionLearningObject ||
    enrolledLearningObject ||
    unenrolledLearningObject;

  const varieties = useMemo(
    () => learningObject?.varieties?.(),
    [learningObject]
  );

  const variety = useMemo(
    () =>
      learningObject?.variety?.() ||
      learningObject?.varieties?.().find((item) => item.id === varietyId) ||
      learningObject?.varieties?.()[0],
    [learningObject, varietyId]
  );

  const { hierarchy } = useLearningRouteProvider(true) || {};
  const quizSubjectFromRoute = hierarchy?.find((item) => 'quiz' in item) as
    | undefined
    | ILearningSubjectWithResources;
  const [{ data: quiz }, { isLoading: isQuizLoading }] = useQuiz(
    {
      quiz: learningObject?.quiz?.().id,
      include: ['learning_subject'],
    },
    {
      enabled: !!learningObject?.quiz && !hierarchy,
      staleTime: Infinity,
    }
  );

  const isLoading =
    (isEnrolledLoading && hasEnrollment) ||
    (isUnenrolledLoading && !hasEnrollment) ||
    isExpositionLoading ||
    isQuizLoading;

  const context = useMemo(
    () => ({
      isLoading,
      hasEnrollment,
      learningObject,
      variety,
      varieties,
      dispatch,
      shared,
    }),
    [hasEnrollment, isLoading, learningObject, varieties, variety, shared]
  );

  const quizSubject = quizSubjectFromRoute || quiz?.learningSubject?.().id;

  return (
    <LearningObjectContext.Provider value={context}>
      <Wrap
        if={!!quizSubject}
        with={LearningSubjectProvider}
        props={
          typeof quizSubject === 'string'
            ? { id: quizSubject }
            : { learningSubject: quizSubject }
        }
      >
        {children}
      </Wrap>
    </LearningObjectContext.Provider>
  );
};
