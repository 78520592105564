import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LearningSubject,
  LearningSubjectCreateInput,
  LearningSubjectFilters,
  LearningSubjectIncludes,
  LearningSubjectSorts,
  LearningSubjectUpdateInput,
} from '../Types/Resources/LearningSubject';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILearningSubjectProps
  extends HookParams<
    typeof LearningSubjectIncludes,
    typeof LearningSubjectFilters,
    typeof LearningSubjectSorts
  > {
  learningSubject?: UUID;
}

export function useLearningSubject(
  { learningSubject, ...queryParameters }: ILearningSubjectProps = {},
  settings?: IUseQueryOptions<DataDocument<LearningSubject>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { learningSubject: defaultLearningSubject },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningSubject || defaultLearningSubject),
      baseName: 'v1.learningSubjects',
      baseUri: '/v1/learning_subjects',
      uri: `/${learningSubject}`,
      invalidate: ['learning_subjects', 'learning_subject'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LearningSubject,
    LearningSubjectCreateInput,
    LearningSubjectUpdateInput
  >(request, settings);
}

export function useLearningSubjects(
  { ...queryParameters }: Omit<ILearningSubjectProps, 'learningSubject'> = {},
  settings?: IUseQueryOptions<DataDocument<LearningSubject[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.learningSubjects',
      baseUri: '/v1/learning_subjects',
      invalidate: ['learning_subjects', 'learning_subject'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningSubject, LearningSubjectCreateInput>(
    request,
    settings
  );
}
