import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  AnswerGroup,
  AnswerGroupCreateInput,
  AnswerGroupFilter,
  AnswerGroupIncludes,
  AnswerGroupSorts,
  AnswerGroupUpdateInput,
} from '../Types/Resources/AnswerGroups';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IAnswerGroupProps
  extends HookParams<
    typeof AnswerGroupIncludes,
    AnswerGroupFilter,
    typeof AnswerGroupSorts
  > {
  answerGroup?: UUID;
}

export function useAnswerGroup(
  { answerGroup, ...queryParameters }: IAnswerGroupProps = {},
  settings?: IUseQueryOptions<DataDocument<AnswerGroup>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { answerGroup: defaultAnswerGroup },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(answerGroup || defaultAnswerGroup),
      baseName: 'v1.answerGroups',
      baseUri: '/v1/answer_groups',
      invalidate: [
        'answer_group',
        'answer_groups',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObject',
      ],
      uri: `/${answerGroup}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    AnswerGroup,
    AnswerGroupCreateInput,
    AnswerGroupUpdateInput
  >(request, settings);
}

export function useAnswerGroups(
  { ...queryParameters }: Omit<IAnswerGroupProps, 'answerGroup'> = {},
  settings?: IUseQueryOptions<DataDocument<AnswerGroup[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.answerGroups',
      invalidate: [
        'v1.enrollments.learningObjects',
        'enrollments.learningObjects',
        'answer_groups',
        'answer_group',
      ],
      baseUri: '/v1/answer_groups',
      queryParameters,
    },
    context
  );
  return useIndexCR<AnswerGroup, AnswerGroupCreateInput>(request, settings);
}
