import { createContext } from 'react';
import { Enrollment } from '@brainstud/academy-api/Types/Resources/Enrollment';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { LearningObjectCollection } from '@brainstud/academy-api/Types/Resources/LearningObjectCollection';
import { LearningRoute } from '@brainstud/academy-api/Types/Resources/LearningRoute';
import { LearningRouteNode } from '@brainstud/academy-api/Types/Resources/LearningRouteNode';
import { LearningSubject } from '@brainstud/academy-api/Types/Resources/LearningSubject';

export type ParentType =
  | ILearningSubjectWithResources
  | ILearningRouteNodeWithResources;

export interface ILearningObjectWithResources extends LearningObject {
  parent?: ParentType;
}

export interface ILearningSubjectWithResources
  extends Omit<LearningSubject, 'scheme'> {
  scheme: Array<ILearningObjectWithResources | ILearningSubjectWithResources>;
  parent?: ParentType;
}

export interface ILearningRouteNodeWithResources
  extends Omit<LearningRouteNode, 'scheme'> {
  scheme: Array<ILearningObjectWithResources | ILearningSubjectWithResources>;
}

export type TNodeListItem =
  | ILearningRouteNodeWithResources
  | ILearningObjectWithResources
  | ILearningSubjectWithResources;

export interface ILearningRouteContext {
  isLoading: boolean;
  learningRoute?: LearningRoute;
  learningObjectCollection?: LearningObjectCollection;
  enrollment?: Enrollment;
  nodes: Array<ILearningRouteNodeWithResources>;
  list?: Array<TNodeListItem>;
  isCustomCollection?: boolean;
  isCustomRoute?: boolean;
  layout?: string;
  pageTree: {
    currentItem?: TNodeListItem;
    nextItem?: TNodeListItem;
    previousItem?: TNodeListItem;
    rootItem?: TNodeListItem;
  };
  hierarchy: Array<TNodeListItem>;
  enrollmentId?: string;
  isLive: boolean;
}

export type TProgress = {
  /** The number of learning objects recursively within this group that have been completed */
  madeObjects: number;
  /** The total number of learning objects recursively within this group  */
  totalObjects: number;
  /** The experience points received of the learning objects recursively within this group  */
  xpReceived: number;
  /** The total sum of the experience points available of the learning objects recursively within this group */
  xpAvailable: number;
  /** Percentage of xp's received relative to the total number of XPs that could be received */
  xpPercentage: 0;
  /** Percentage of objects made relative to the total number of objects */
  madeObjectsPercentage: 0;
  /** XP percentage, unless no XPs have been appointed, then a percentage based on the number of objects */
  percentage: 0;
  /** Number of objects that have been approved */
  acceptedObjects: 0;
  /** Number of objects that have been rejected */
  rejectedObjects: 0;
  /** Number of objects that have been turned in */
  turnedInObjects: 0;
};

export type TProgressFn = (
  items: Array<TNodeListItem>,
  progress?: TProgress
) => TProgress;

export const LearningRouteContext = createContext<null | ILearningRouteContext>(
  null
);
