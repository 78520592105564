import { useSessionStorage } from 'Hooks/BrowserStorage/useSessionStorage';
import { IAnswerReviewState } from '../../Providers/AnswerReviewProvider/useAnswerReviewReducer';

export function useReviewState() {
  return useSessionStorage<Omit<IAnswerReviewState, 'isLoading'>>(
    'review.state',
    {
      origin: '/coach/review',
      index: 0,
      reviewSet: [],
    }
  );
}
