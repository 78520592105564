import { createContext, Dispatch, SetStateAction } from 'react';
import { Answer } from '@brainstud/academy-api/Types/Resources/Answer';
import { IAnswerReviewContext } from 'Modules/coach-panel/Providers/AnswerReviewProvider';

export interface IAnswerContext {
  currentAnswer?: Answer;
  setSavedAnswer: (answer: Answer) => void;
  setAnswerIndex: (index: number) => void;
  answerIndex: number;
  setSelectedAnswerId: Dispatch<
    SetStateAction<IAnswerReviewContext['answerId']>
  >;
  lastAnswer?: Answer;
  answers: Answer[];
}

export const AnswerContext = createContext<null | IAnswerContext>(null);
