import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Answer,
  AnswerCreateInput,
  AnswerFilters,
  AnswerIncludes,
  AnswerSorts,
  AnswerUpdateInput,
} from '../Types/Resources/Answer';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IAnswerProps
  extends HookParams<typeof AnswerIncludes, AnswerFilters, typeof AnswerSorts> {
  answer?: UUID;
}

export function useAnswer(
  { answer, ...queryParameters }: IAnswerProps = {},
  settings?: IUseQueryOptions<DataDocument<Answer>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { answer: defaultAnswer },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(answer || defaultAnswer),
      baseName: 'v1.answers',
      baseUri: '/v1/answers',
      invalidate: [
        'answer',
        'answers',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
        'v1.enrollments.learningObjectCollections',
      ],
      uri: `/${answer}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Answer, AnswerCreateInput, AnswerUpdateInput>(
    request,
    settings
  );
}

export function useAnswers(
  { ...queryParameters }: Omit<IAnswerProps, 'answer'> = {},
  settings?: IUseQueryOptions<DataDocument<Answer[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.answers',
      invalidate: [
        'answer',
        'answers',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
        'v1.enrollments.learningObjectCollections',
        'v1.enrollments',
        'enrollments',
        'enrollment',
      ],
      baseUri: '/v1/answers',
      queryParameters,
    },
    context
  );
  return useIndexCR<Answer, AnswerCreateInput>(request, settings);
}
