import { createContext, Dispatch } from 'react';
import { UnenrolledLearningObject } from '@brainstud/academy-api/Hooks/useLearningObjects';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { LearningObjectVariety } from '@brainstud/academy-api/Types/Resources/LearningObjectVariety';
import { TLearningObjectActions } from './LearningObjectReducer';

export type TLearningObjectIndeterminate = UnenrolledLearningObject &
  Pick<Partial<LearningObject>, 'userState'>;

export interface ILearningObjectContext {
  learningObject?: TLearningObjectIndeterminate;
  isLoading: boolean;
  hasEnrollment: boolean;
  variety?: LearningObjectVariety;
  varieties?: LearningObjectVariety[];
  dispatch: Dispatch<TLearningObjectActions>;
  shared?: boolean;
}

export const LearningObjectContext =
  createContext<null | ILearningObjectContext>(null);
