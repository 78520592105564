import React, { ReactNode, useMemo } from 'react';
import { useLearningSubject } from '@brainstud/academy-api/Hooks/useLearningSubjects';
import { LearningSubject } from '@brainstud/academy-api/Types/Resources/LearningSubject';
import { useRouter } from 'next/router';
import {
  ILearningSubjectWithResources,
  TNodeListItem,
} from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { Wrap } from '../../Components/Wrap';
import { isLearningSubject } from '../../Modules/action-based-learning-panel/Utils/isLearningGroupSubjectObject';
import { AnswerGroupProvider } from '../AnswerGroupProvider';
import { expandNodeToList } from '../LearningRouteProvider/expandNodeToList';
import { LearningSubjectContext } from './LearningSubjectContext';

type Props = {
  id?: string;
  learningSubject?: LearningSubject | ILearningSubjectWithResources;
  children: ReactNode;
};

/**
 * LearningSubjectProvider.
 *
 * Provides access to the learning subject either from the learning route, specially fetched or provided through
 * the parameters. It will find the learning subject based on either the route parameters or the provided id.
 */
export const LearningSubjectProvider = ({
  id,
  learningSubject: providedLearningSubject,
  children,
}: Props) => {
  const { query } = useRouter();
  const { learningSubjectId: routeId } = query as {
    learningSubjectId?: string;
  };
  const learningSubjectId = providedLearningSubject?.id || id || routeId;

  const route = useLearningRouteProvider(true);
  const routeLearningSubject = useMemo(
    () =>
      (route?.list || []).find(
        (item) => item.id === learningSubjectId && isLearningSubject(item)
      ) as undefined | ILearningSubjectWithResources,
    [route, learningSubjectId]
  );

  const [{ data: fetchedLearningSubject }, { isLoading }] = useLearningSubject(
    {
      learningSubject: learningSubjectId,
      include: ['quiz', 'learning_objects'],
    },
    { enabled: !providedLearningSubject && !routeLearningSubject }
  );

  const learningSubject =
    providedLearningSubject || fetchedLearningSubject || routeLearningSubject;

  const subnodes = useMemo(
    () => expandNodeToList(learningSubject as TNodeListItem).flat(),
    [learningSubject]
  );

  const quiz = learningSubject?.quiz?.();
  const usesAnswerGroups = !!quiz;

  const context = useMemo(
    () => ({
      isLoading,
      learningSubject,
      usesAnswerGroups,
      quiz,
      subnodes,
    }),
    [isLoading, learningSubject, quiz, subnodes, usesAnswerGroups]
  );

  return (
    <LearningSubjectContext.Provider value={context}>
      <Wrap
        if={usesAnswerGroups}
        with={AnswerGroupProvider}
        props={{ learningSubject: learningSubjectId }}
      >
        {children}
      </Wrap>
    </LearningSubjectContext.Provider>
  );
};
