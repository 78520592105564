import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LearningObject,
  LearningObjectCreateInput,
  LearningObjectFilters,
  LearningObjectIncludes,
  LearningObjectSorts,
  LearningObjectUpdateInput,
} from '../Types/Resources/LearningObject';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILearningObjectProps
  extends HookParams<
    typeof LearningObjectIncludes,
    LearningObjectFilters,
    typeof LearningObjectSorts
  > {
  learningObject?: UUID;
}

export type UnenrolledLearningObject = Omit<LearningObject, 'userState'>;

export function useLearningObject(
  { learningObject, ...queryParameters }: ILearningObjectProps = {},
  settings?: IUseQueryOptions<
    DataDocument<UnenrolledLearningObject>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { learningObject: defaultLearningObject },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(learningObject || defaultLearningObject),
      baseName: 'v1.learningObjects',
      baseUri: '/v1/learning_objects',
      invalidate: [
        'learning_object',
        'learning_objects',
        'v1.enrollments.learningObjects',
      ],
      uri: `/${learningObject}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<UnenrolledLearningObject, LearningObjectUpdateInput>(
    request,
    settings
  );
}

export function useLearningObjects(
  queryParameters: Omit<ILearningObjectProps, 'learningObject'> = {},
  settings?: IUseQueryOptions<
    DataDocument<UnenrolledLearningObject[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.learningObjects',
      baseUri: '/v1/learning_objects',
      invalidate: [
        'learning_object',
        'learning_objects',
        'v1.enrollments.learningObjects',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<UnenrolledLearningObject, LearningObjectCreateInput>(
    request,
    settings
  );
}
