import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { Answer } from '@brainstud/academy-api/Types/Resources/Answer';
import { useLearningObjectProvider } from 'Providers/LearningObjectProvider/useLearningObjectProvider';
import {
  BlockState,
  ContentBlocksContext,
  IContentBlocksContext,
} from './ContentBlocksContext';
import {
  ACTIONS,
  contentBlocksInitialState,
  ContentBlocksReducer,
} from './ContentBlocksReducer';
import { useBlocksHelper } from './useBlocksHelper';

export type TAssignmentValuesProps = {
  /** Sets the answer set within the AssignmentForm */
  answer?: Answer;
  /** Disables all interaction with the Assignment */
  readonly?: boolean;
};

export const ContentBlocksProvider = ({
  answer,
  readonly,
  children,
}: PropsWithChildren<TAssignmentValuesProps>) => {
  const { learningObject, variety, isLoading } = useLearningObjectProvider();
  const hasStaticVariety = learningObject?.variety?.().contentType === 'STATIC';
  const [{ isRead, isStatic, isSubmittable, states }, dispatch] = useReducer(
    ContentBlocksReducer,
    contentBlocksInitialState
  );

  const content = useMemo(
    () =>
      readonly
        ? answer?.learningObjectVariety?.().content || variety?.content
        : variety?.content,
    [answer, readonly, variety?.content]
  );

  const blockList = useBlocksHelper(
    Array.isArray(content) ? content : content?.components || []
  );

  const setBlockById = useCallback(
    (id, values = {}) =>
      dispatch({
        type: ACTIONS.SET_BLOCK_STATES,
        payload: [{ id, ...values }],
      }),
    [dispatch]
  );

  const resetTouched = useCallback(
    () => dispatch({ type: ACTIONS.RESET_TOUCHED }),
    [dispatch]
  );

  useEffect(() => {
    if (blockList.length) {
      dispatch({
        type: ACTIONS.SET_BLOCK_STATES,
        payload: blockList.map(({ id, type }) => ({ id, type }) as BlockState),
      });
    }
  }, [blockList]);

  const context = useMemo<IContentBlocksContext>(
    () => ({
      isStatic: hasStaticVariety || isStatic,
      blocks: blockList,
      states,
      isLoading,
      setBlockById,
      readonly,
      isRead,
      isSubmittable,
      resetTouched,
    }),
    [
      blockList,
      isLoading,
      setBlockById,
      readonly,
      resetTouched,
      hasStaticVariety,
      isRead,
      isStatic,
      isSubmittable,
      states,
    ]
  );

  return (
    <ContentBlocksContext.Provider value={context}>
      {children}
    </ContentBlocksContext.Provider>
  );
};
