import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LearningObject,
  LearningObjectCreateInput,
  LearningObjectFilters,
  LearningObjectIncludes,
  LearningObjectSorts,
  LearningObjectUpdateInput,
} from '../Types/Resources/LearningObject';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILearningObjectProps
  extends HookParams<
    typeof LearningObjectIncludes,
    LearningObjectFilters,
    typeof LearningObjectSorts
  > {
  exposition?: UUID;
  learningObject?: UUID;
}

export function useExpositionLearningObject(
  { exposition, learningObject, ...queryParameters }: ILearningObjectProps = {},
  settings?: IUseQueryOptions<DataDocument<LearningObject>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      learningObject: defaultLearningObject,
      enrollment: defaultEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(learningObject || defaultLearningObject) &&
        !!(exposition || defaultEnrollment),
      baseName: 'v1.expositions.learningObjects',
      baseUri: `/v1/expositions/${exposition || defaultEnrollment}/learning_objects`,
      uri: `/${learningObject || defaultLearningObject}`,
      invalidate: ['learning_objects', 'learning_object', 'v1.learningObjects'],
      queryParameters,
      shareable: true,
    },
    context
  );
  return useSingleCRUD<
    LearningObject,
    LearningObjectCreateInput,
    LearningObjectUpdateInput
  >(request, settings);
}
