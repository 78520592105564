import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useAnswerGroups } from '@brainstud/academy-api/Hooks/useAnswerGroups';
import { LearningSubject } from '@brainstud/academy-api/Types/Resources/LearningSubject';
import { useUserData } from 'Hooks/UserData';
import { useLearningRouteProvider } from '../LearningRouteProvider/useLearningRouteProvider';
import { AnswerGroupContext } from './AnswerGroupContext';

type Props = {
  learningSubject?: LearningSubject | string;
  children?: ReactNode;
};

/**
 * AnswerGroupProvider.
 *
 * Provides the most appropriate answer group as the current answer group.
 */
export const AnswerGroupProvider = ({ learningSubject, children }: Props) => {
  const [account] = useUserData();
  const { enrollmentId } = useLearningRouteProvider(true) || {};

  const learningSubjectId =
    typeof learningSubject === 'object' ? learningSubject.id : learningSubject;
  const [{ data, create }, { isLoading }] = useAnswerGroups(
    {
      include: ['answers', 'answers.learning_object'],
      filter: {
        learning_subject: learningSubjectId,
        account: account?.id,
      },
    },
    { enabled: !!learningSubject && !!account }
  );

  const answerGroups = useMemo(() => [...data].reverse(), [data]);

  const [selectedAnswerGroupId, setAnswerGroupId] = useState<string>();

  const currentAnswerGroup = useMemo(
    () =>
      (selectedAnswerGroupId &&
        answerGroups.find((item) => item.id === selectedAnswerGroupId)) ||
      answerGroups.find((item) => ['OPEN', 'CONCEPT'].includes(item.status)) ||
      answerGroups[0],
    [selectedAnswerGroupId, answerGroups]
  );

  const createNewAnswerGroup = useCallback(() => {
    if (!enrollmentId) {
      throw Error(
        'Cannot create answer group when there is no enrollment available'
      );
    }
    return create.mutateAsync({
      status: 'CONCEPT',
      relationships: {
        learning_subject: learningSubjectId,
        enrollment: enrollmentId,
      },
    });
  }, [create, enrollmentId, learningSubjectId]);

  const context = useMemo(
    () => ({
      isLoading,
      currentAnswerGroup,
      answerGroups,
      setAnswerGroupId,
      createNewAnswerGroup,
    }),
    [isLoading, currentAnswerGroup, answerGroups, createNewAnswerGroup]
  );

  return (
    <AnswerGroupContext.Provider value={context}>
      {children}
    </AnswerGroupContext.Provider>
  );
};
