import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Enrollment,
  EnrollmentCreateInput,
  EnrollmentFilters,
  EnrollmentIncludes,
  EnrollmentSorts,
  EnrollmentUpdateInput,
} from '../Types/Resources/Enrollment';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IEnrollmentProps
  extends HookParams<
    typeof EnrollmentIncludes,
    typeof EnrollmentFilters,
    typeof EnrollmentSorts
  > {
  enrollment?: UUID;
}

export function useEnrollment(
  { enrollment, ...queryParameters }: IEnrollmentProps = {},
  settings?: IUseQueryOptions<DataDocument<Enrollment>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments',
      baseUri: '/v1/enrollments',
      invalidate: [
        'enrollments',
        'enrollment',
        'v1.portals.courses',
        'courses',
      ],
      uri: `/${enrollment || defaultEnrollment}`,
      queryParameters,
    },
    context
  );

  return useSingleCRUD<
    Enrollment,
    EnrollmentCreateInput,
    EnrollmentUpdateInput
  >(request, settings);
}

export function useEnrollments(
  { ...queryParameters }: Omit<IEnrollmentProps, 'enrollment'> = {},
  settings?: IUseQueryOptions<DataDocument<Enrollment[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollments',
      baseUri: '/v1/enrollments',
      invalidate: [
        'enrollments',
        'enrollment',
        'v1.portals.courses',
        'courses',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<Enrollment, EnrollmentCreateInput>(request, settings);
}
