import React, {
  FormEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAnswer } from '@brainstud/academy-api/Hooks/useAnswers';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { AnswerStatus } from '@brainstud/academy-api/Types/Resources/Answer';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { Form } from '@brainstud/universal-components/Components/Form';
import { useCurrentLearningObjectAnswers } from 'Hooks/CurrentLearningObjectAnswer/useCurrentLearningObjectAnswers';
import { getValidatedFormState } from 'Modules/universal-components/Components/Form/FormUtils';
import {
  TField,
  TFormSavedState,
  TInputList,
} from 'Modules/universal-components/Components/Form/Types';
import { useAnswerGroupProvider } from 'Providers/AnswerGroupProvider/useAnswerGroupProvider';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { useLearningObjectProvider } from 'Providers/LearningObjectProvider/useLearningObjectProvider';
import { useSystemEventProvider } from 'Providers/SystemEventProvider/useSystemEventProvider';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { useAnswerProvider } from '../useAnswerProvider';
import { AnswerFormInner } from './AnswerFormInner';
import { AssignmentTranslator } from './Translator';
import { AssignmentValidator } from './Validator';

type TProps = {
  /** The learning object to be loaded */
  identifier: string;
  /** Whether feedback should be shown when submitted */
  feedback?: boolean;
  /** Whether to load the last answers automatically */
  autoLoad?: boolean | AnswerStatus[];
  /** Whether you should be able to answer the learning object */
  disabled?: boolean;
  /** Sets the default status that will be sent when the learning object is submitted */
  status?: 'CONCEPT' | 'TURNED_IN';
  /** Handler executed when blocks have incorrect given answers */
  onIncorrect?: (val: {
    [key: string]: string | number | Array<string | number>;
  }) => void;
  /** Handler executed when all blocks have a correct given answer */
  onCorrect?: (val: {
    [key: string]: string | number | Array<string | number>;
  }) => void;
  /** Handler executed when submitting the learning object answer */
  onSubmit?: (val: {
    [key: string]: string | number | Array<string | number>;
  }) => void;
  /** passing the LearningObject is also possible for e.g. unauthenticated access */
  learningObject?: LearningObject;
  children: ReactNode;
  className?: string;
};

/**
 * The AnswerForm component is a wrapper component for the Form component meant for validating Learning Objects
 *
 * This wrapper sets all necessary properties to the Form for handling validation of learning objects.
 * Next to that, it instantiates an AssignmentProvider with the necessary properties, so you can easily
 * access block and assignment information.
 */
export const AnswerForm = ({
  identifier,
  feedback = true,
  autoLoad = true,
  disabled,
  learningObject: providedLearningObject,
  status: defaultStatus = 'TURNED_IN',
  onIncorrect,
  onCorrect,
  onSubmit,
  children,
  className,
}: PropsWithChildren<TProps>) => {
  const { readonly } = useContentBlocksProvider();
  const { learningObject: fetchedLearningObject } = useLearningObjectProvider();
  const learningObject = providedLearningObject || fetchedLearningObject;
  const [isReset, setIsReset] = useState(false);
  const { currentAnswer, setSavedAnswer, setAnswerIndex, answerIndex } =
    useAnswerProvider();
  const { currentAnswerGroup } = useAnswerGroupProvider(true) || {};
  const answerGroupId = currentAnswerGroup?.id;
  const { status: answerStatus } =
    useCurrentLearningObjectAnswers(learningObject);
  useBreadcrumbs({ [identifier]: learningObject?.title }, [learningObject]);

  const [{ createOrUpdate: createOrUpdateAnswer }] = useAnswer(
    {
      answer:
        currentAnswer?.status === 'CONCEPT' ? currentAnswer.id : undefined,
    },
    { enabled: false }
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const { emit } = useSystemEventProvider();
  const saveAnswer = useCallback(
    (
      state: TFormSavedState<TInputList, { score?: number }>,
      { status, isStatic } = {
        status: defaultStatus,
        isStatic: false,
      },
      callbacks = {}
    ) => {
      if (learningObject && !isSaving) {
        emit(
          'saving answer',
          { ...learningObject },
          isStatic ? 'ACCEPTED' : status
        );
        setIsSaving(true);
        try {
          const validity = Object.keys(state.values).reduce(
            (scores, key) => ({
              ...scores,
              [key]: state.fields
                .filter((field) => field.name === key)
                .every((field) => field.score === 1),
            }),
            {}
          );
          const score =
            Object.values(validity).filter((val) => val).length /
            Object.values(validity).length;
          return createOrUpdateAnswer.mutateAsync(
            {
              ...(status === 'CONCEPT' ? { _invalidate: [] } : {}),
              learning_object: identifier,
              status,
              given_answer: JSON.stringify({
                state,
                isStatic,
                values: state.values,
                validity,
              }),
              score,
              ...(answerGroupId
                ? {
                    relationships: {
                      answer_group: answerGroupId,
                    },
                  }
                : {}),
            },
            isMounted.current
              ? {
                  onSettled: (...args) => {
                    setIsSaving(false);
                    setAnswerIndex(0);
                    if (callbacks.onSettled) callbacks.onSettled(...args);
                  },
                  onError: callbacks.onError,
                  onSuccess: (response, ...args) => {
                    setSavedAnswer(response.data);
                    callbacks?.onSuccess?.(response, ...args);
                  },
                }
              : undefined
          );
        } catch (error) {
          setIsSaving(false);
          return Promise.reject(error);
        }
      }
    },
    [
      defaultStatus,
      learningObject,
      isSaving,
      emit,
      createOrUpdateAnswer,
      identifier,
      answerGroupId,
      setAnswerIndex,
      setSavedAnswer,
    ]
  );

  const { invalidateQueries } = useApi();
  const isConcept = !currentAnswer || answerStatus === 'CONCEPT' || isReset;
  const learningObjectId = learningObject?.id;
  const handleUnmount = useCallback(
    (state) => {
      if (state.dirty && isConcept && !readonly && !state.disabled) {
        saveAnswer(
          {
            disabled: false,
            ...getValidatedFormState(state, { status: 'CONCEPT' }),
          },
          { status: 'CONCEPT', isStatic: false }
        );
      }
      if (learningObjectId && (currentAnswer || (state.dirty && isConcept))) {
        setTimeout(() => {
          invalidateQueries([`/${learningObjectId}`, 'v1.answerGroups']);
        }, 500);
      }
    },
    [
      currentAnswer,
      isConcept,
      readonly,
      learningObjectId,
      saveAnswer,
      invalidateQueries,
    ]
  );

  const [loadedAnswerTimestamp, setLoadedAnswerTimestamp] = useState<string>();
  const { isCoachEnvironment } = useEnvironmentProvider();
  const loadAnswer = useCallback(() => {
    setLoadedAnswerTimestamp(currentAnswer?.updatedAt);
    const formState = currentAnswer?.givenAnswer?.state;
    return formState
      ? {
          validate:
            isCoachEnvironment ||
            ['ACCEPTED', 'REJECTED'].includes(currentAnswer?.status),
          ...formState,
          // Makes sure the new 'evaluated' property is set on all answers
          fields: formState?.fields?.map((item: TField) => ({
            ...item,
            evaluated: currentAnswer?.status !== 'CONCEPT',
          })),
          // Prevents a (saved) disabled form to be disabled when answer is a concept, as this should never be the case.
          disabled: currentAnswer?.status !== 'CONCEPT',
        }
      : false;
  }, [currentAnswer, isCoachEnvironment]);

  const handleCorrect = useCallback(
    (values) => {
      setIsReset(false);
      if (onSubmit) onSubmit(values);
      if (onCorrect) onCorrect(values);
    },
    [onCorrect, onSubmit]
  );

  const handleIncorrect = useCallback(
    (values) => {
      setIsReset(false);
      if (onSubmit) onSubmit(values);
      if (onIncorrect) onIncorrect(values);
    },
    [onIncorrect, onSubmit]
  );

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      setIsReset(!isSaving);
      return !isSaving || event.preventDefault();
    },
    [isSaving]
  );

  const shouldLoadAnswer =
    currentAnswer &&
    !isSaving &&
    (Array.isArray(autoLoad)
      ? autoLoad.includes(currentAnswer.status)
      : autoLoad) &&
    (!loadedAnswerTimestamp ||
      DateFormatter.isAfter(currentAnswer.updatedAt, loadedAnswerTimestamp) ||
      answerIndex > 0);

  return (
    <Form
      className={className}
      loading={!learningObject}
      feedback={feedback ? 'full' : 'standard'}
      autoReset={false}
      onReset={handleReset}
      disabled={disabled}
      onSave={learningObject && !readonly ? saveAnswer : undefined}
      onLoad={shouldLoadAnswer ? loadAnswer : undefined}
      validator={AssignmentValidator}
      translator={AssignmentTranslator}
      onValidationError={handleIncorrect}
      onUnmount={handleUnmount}
      onSubmit={handleCorrect}
    >
      <AnswerFormInner hasAnswer={!!currentAnswer}>{children}</AnswerFormInner>
    </Form>
  );
};
