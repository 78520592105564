import { createContext } from 'react';
import { LearningSubject } from '@brainstud/academy-api/Types/Resources/LearningSubject';
import { Quiz } from '@brainstud/academy-api/Types/Resources/Quiz';
import {
  ILearningSubjectWithResources,
  TNodeListItem,
} from 'Providers/LearningRouteProvider/LearningRouteContext';

export interface ILearningSubjectContext {
  isLoading: boolean;
  learningSubject?: ILearningSubjectWithResources | LearningSubject;
  usesAnswerGroups: boolean;
  quiz?: Quiz;
  subnodes: Array<TNodeListItem>;
}

type PartialLearningSubjectContext = Partial<ILearningSubjectContext> &
  Pick<ILearningSubjectContext, 'subnodes'>;

export const LearningSubjectContext =
  createContext<PartialLearningSubjectContext>({
    isLoading: true,
    subnodes: [],
  });
