import { useEffect, useMemo, useState } from 'react';
import { AnswerStatus } from '@brainstud/academy-api/Types/Resources/Answer';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { useAnswerGroupProvider } from 'Providers/AnswerGroupProvider';
import { useSystemEventProvider } from 'Providers/SystemEventProvider';

/**
 * useLearningObjectAnswers.
 *
 * Provides an accurate status and the list of answers for the current answer group (or simply all known answers).
 */
export function useCurrentLearningObjectAnswers(
  learningObject?: LearningObject,
  states?: AnswerStatus[]
) {
  const [statusOverride, setStatusOverride] = useState<AnswerStatus>();
  const { subscribe } = useSystemEventProvider();
  const learningObjectId = learningObject?.id;
  useEffect(() => {
    if (learningObjectId) {
      return subscribe(
        'saving answer',
        (answeredLearningObject, answerStatus) => {
          if (answeredLearningObject.id === learningObjectId) {
            setStatusOverride(answerStatus);
          }
        }
      );
    }
  }, [learningObjectId, subscribe]);

  const { currentAnswerGroup } = useAnswerGroupProvider(true) || {};

  const answers = useMemo(
    () =>
      (currentAnswerGroup
        ? currentAnswerGroup
            ?.answers?.()
            .filter(
              (item) =>
                item.learningObject?.().id === learningObjectId &&
                (!states || states.includes(item.status))
            )
        : learningObject
            ?.answers?.()
            .filter((item) => !states || states.includes(item.status))) || [],
    [currentAnswerGroup, learningObject, learningObjectId, states]
  );

  const status =
    statusOverride ||
    (currentAnswerGroup
      ? learningObject
          ?.answers?.()
          .find((item) =>
            (answers || []).some(
              (answerGroupItem) => item.id === answerGroupItem.id
            )
          ) || {
          status: 'OPEN',
        }
      : learningObject?.answers?.()[0]
    )?.status ||
    learningObject?.userState?.status ||
    'OPEN';

  return { answers, status };
}
