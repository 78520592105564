import {
  TField,
  TFormValidator,
} from '@brainstud/universal-components/Components/Form/Types';
import validationRules from './rules';

type Message = {
  valid: boolean;
  score: number;
  // message: boolean | string;
  parameter: false | string;
};

/**
 * Validates a value based on a set of rules.
 * @param field {object?} Optional ID to specify exact input
 * @param formValue
 * @returns {score: number, messages: [], id: *}
 */
export const AssignmentValidator: TFormValidator = (
  field: TField,
  formValue,
  context
) => {
  const { rules, value } = field;
  const ruleList = typeof rules === 'string' ? rules.split('|') : rules || [];
  const messages: Message[] = [];
  for (let i = 0; i < ruleList.length; i += 1) {
    const rule = ruleList[i];
    const ruleName = rule.split(':')[0];
    const ruleSegments = [ruleName, rule.split(':').slice(1).join(':')];
    let outcome;
    let score;
    try {
      [outcome, score] = validationRules[ruleSegments[0]](
        value,
        ruleSegments[1],
        formValue
      );
    } catch (e) {
      console.warn(
        '[QUIZ:validator] The rule',
        ruleSegments[0],
        'does not exist or function properly.'
      );
      console.warn(e);
    }

    messages.push({
      valid: outcome,
      score,
      // message: outcome === false && ruleSegments[0],
      parameter: outcome === false && ruleSegments[1],
    });
  }

  const score = messages
    .map((outcome) => outcome.score)
    .reduce((a, b) => a + b, 0);

  return {
    id: field.id,
    messages,
    ...(context?.status !== 'CONCEPT'
      ? { valid: !messages.some((error) => error.valid === false) }
      : {}),
    score: ruleList.length > 0 ? (score === ruleList.length ? 1 : 0) : 1,
  };
};
